import { useCustomerContext } from "@app/providers/customer"
import { CustomerAddressesCard } from "./CustomerAddressesCard"
import { CustomerPageLayout } from "../CustomerPageLayout"
import { memo } from "react"

import type { Props } from "@app/pages/account/addresses"
import type { PageProps } from "@root/types/global"

const CustomerAddresses: React.FC<PageProps<Props>> = ({ location, page }) => {
  const { customer } = useCustomerContext()

  return (
    <CustomerPageLayout title={page?.title} content={page?.content} location={location} subtitle={page?.subtitle}>
      {customer?.id && <CustomerAddressesCard customer={customer} />}
    </CustomerPageLayout>
  )
}

export default memo(CustomerAddresses)
