import { memo } from "react"
import { useCustomerAddress } from "@app/hooks/useCustomer"
import { CustomerAddressesForm } from "./CustomerAddressesForm"
import { CustomerAddressesList } from "./CustomerAddressesList"

type CustomerAddressesCardProps = {
  customer: any
}

const CustomerAddressesCard: React.FC<CustomerAddressesCardProps> = ({ customer }) => {
  const { addresses, address, setAddress, updateAddress, deleteAddress, defaultAddress, initialData, loading, saving, errors } =
    useCustomerAddress(customer)

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    if (address?.action === "update") await updateAddress(address?.id, address)
  }

  const handleChange = ({ target: { type, name, value, checked } }: React.ChangeEvent<HTMLInputElement>) =>
    setAddress(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))

  const handleEdit = id =>
    setAddress({
      action: "update",
      ...addresses?.filter(address => address?.id === id)[0],
    })

  const handleCancel = () => setAddress({ ...initialData })

  const handleDelete = async id => await deleteAddress(id)

  const handleDefault = async id => await defaultAddress(id)

  return (
    <>
      {address?.action?.length ? (
        <CustomerAddressesForm
          address={address}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          loading={loading || saving}
          errors={errors}
        />
      ) : (
        <CustomerAddressesList
          addresses={addresses}
          loading={loading || saving}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          handleDefault={handleDefault}
        />
      )}
    </>
  )
}

const MemoCustomerAddressesCard = memo(CustomerAddressesCard)
export { MemoCustomerAddressesCard as CustomerAddressesCard }
