import { memo } from "react"
import { Box, Text, Stack, VStack, FormControl, FormErrorMessage, FormLabel, Input, HStack, Button } from "@chakra-ui/react"

type CustomerAddressesFormProps = {
  address: any
  handleChange?: (event: any) => void
  handleSubmit?: (event: any) => void
  handleCancel?: () => void
  loading: boolean
  errors: any
}

const CustomerAddressesForm: React.FC<CustomerAddressesFormProps> = ({
  address,
  handleChange,
  handleSubmit,
  handleCancel,
  loading,
  errors,
}) => {
  return (
    <Box border="1px solid" borderColor="brand.avocado" p={8} w="full" spacing={4}>
      <Text variant="caps" mb={6}>
        Edit Address
      </Text>
      <Box as="form" onSubmit={handleSubmit}>
        <VStack width="full" spacing="6">
          <Stack direction={["column", "row"]} width="full" spacing="4">
            <FormControl id="firstName" isInvalid={errors?.firstName} isRequired>
              <FormLabel>First name</FormLabel>
              <Input type="text" name="firstName" value={address?.firstName} onChange={handleChange} maxLength={255} />
              <FormErrorMessage>{errors?.firstName?.message}</FormErrorMessage>
            </FormControl>

            <FormControl id="lastName" isInvalid={errors?.lastName} isRequired>
              <FormLabel>Last name</FormLabel>
              <Input type="text" name="lastName" value={address?.lastName} onChange={handleChange} maxLength={255} />
              <FormErrorMessage>{errors?.lastName?.message}</FormErrorMessage>
            </FormControl>
          </Stack>

          <Stack direction={["column", "row"]} width="full" spacing="4">
            <FormControl id="company" isInvalid={errors?.company}>
              <FormLabel>Company</FormLabel>
              <Input type="text" name="company" value={address?.company} onChange={handleChange} />
              <FormErrorMessage>{errors?.company?.message}</FormErrorMessage>
            </FormControl>

            <FormControl id="phone" isInvalid={errors?.phone}>
              <FormLabel>Phone</FormLabel>
              <Input type="text" name="phone" value={address?.phone} onChange={handleChange} />
              <FormErrorMessage>{errors?.phone?.message}</FormErrorMessage>
            </FormControl>
          </Stack>

          <FormControl id="address1" isInvalid={errors?.address1} isRequired>
            <FormLabel>Address 1</FormLabel>
            <Input type="text" name="address1" value={address?.address1} onChange={handleChange} maxLength={255} />
            <FormErrorMessage>{errors?.address1?.message}</FormErrorMessage>
          </FormControl>

          <FormControl id="address2" isInvalid={errors?.address2}>
            <FormLabel>Address 2</FormLabel>
            <Input type="text" name="address2" value={address?.address2} onChange={handleChange} maxLength={255} />
            <FormErrorMessage>{errors?.address2?.message}</FormErrorMessage>
          </FormControl>

          <Stack direction={["column", "row"]} width="full" spacing="4">
            <FormControl id="city" isInvalid={errors?.city} isRequired>
              <FormLabel>City</FormLabel>
              <Input type="text" name="city" value={address?.city} onChange={handleChange} maxLength={255} />
              <FormErrorMessage>{errors?.city?.message}</FormErrorMessage>
            </FormControl>

            <FormControl id="province" isInvalid={errors?.province} isRequired>
              <FormLabel>State</FormLabel>
              <Input type="text" name="province" value={address?.province} onChange={handleChange} maxLength={255} />
              <FormErrorMessage>{errors?.province?.message}</FormErrorMessage>
            </FormControl>

            <FormControl id="zip" isInvalid={errors?.zip} isRequired>
              <FormLabel>ZIP</FormLabel>
              <Input type="number" name="zip" value={address?.zip} onChange={handleChange} maxLength={255} />
              <FormErrorMessage>{errors?.zip?.message}</FormErrorMessage>
            </FormControl>
          </Stack>

          <FormControl id="country" isInvalid={errors?.country} isRequired>
            <FormLabel>Country</FormLabel>
            <Input type="text" name="country" value={address?.country} onChange={handleChange} maxLength={255} />
            <FormErrorMessage>{errors?.country?.message}</FormErrorMessage>
          </FormControl>

          <HStack width="full">
            <Button type="submit" disabled={loading} isLoading={loading}>
              Save
            </Button>
            <Button disabled={loading} onClick={() => handleCancel()} variant="outline">
              Cancel
            </Button>
          </HStack>

          {errors?.message && (
            <FormControl id="error" isInvalid>
              <FormErrorMessage>{errors.message}</FormErrorMessage>
            </FormControl>
          )}
        </VStack>
      </Box>
    </Box>
  )
}

const MemoCustomerAddressesForm = memo(CustomerAddressesForm)
export { MemoCustomerAddressesForm as CustomerAddressesForm }
