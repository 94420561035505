import { memo } from "react"
import { Button, Stack, Box, Text } from "@chakra-ui/react"

// import { useCustomerContext } from "@app/providers/customer"
// import { useCustomerAddress } from "@app/hooks/useCustomer"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"

type CustomerAddressesItemProps = {
  primaryAddress: boolean
  address: any
  handleDefault?: (id: string) => void
  handleDelete?: (id: string) => void
  handleEdit?: (id: string) => void
  loading: boolean
}

const CustomerAddressesItem: React.FC<CustomerAddressesItemProps> = ({
  address,
  primaryAddress = false,
  handleDefault,
  handleDelete,
  handleEdit,
  loading,
}) => {
  // const [isDefault, setIsDefault] = useState(address?.default || false)
  // const { customer } = useCustomerContext()
  // const { defaultAddress, saving } = useCustomerAddress(customer)
  const locales = useLocale(LOCALE_KEYS.ACCOUNT)

  return (
    address && (
      <Box key={address?.id} py="6" pr="8" bg="brand.offWhite">
        <Stack direction={["column", "row"]} width="full">
          <Box width="full">
            <Text fontWeight={700} fontSize="xl">
              {address?.name}
            </Text>
            <Text fontSize="md" mt={2}>
              {address?.formatted?.join(", ")}
            </Text>
            <Text>{address?.phone}</Text>
          </Box>
          <Stack>
            {!address?.default && (
              <Button variant="outline" onClick={() => handleDefault(address?.id)} isLoading={loading}>
                {locales?.accountAdressMakePrimary}
              </Button>
            )}
            {!primaryAddress && (
              <>
                <Button variant="outline" onClick={() => handleEdit(address?.id)} isLoading={loading}>
                  {locales?.accountAddressEdit}
                </Button>
                {!address?.default && (
                  <Button variant="outline" onClick={() => handleDelete(address?.id)} isLoading={loading}>
                    Delete
                  </Button>
                )}
              </>
            )}
          </Stack>
        </Stack>
      </Box>
    )
  )
}

const MemoCustomerAddressesItem = memo(CustomerAddressesItem)
export { MemoCustomerAddressesItem as CustomerAddressesItem }
