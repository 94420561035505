import { Box, Stack, Spinner, Heading, Text } from "@chakra-ui/react"
import { CustomerAddressesItem } from "./CustomerAddressesItem"

type CustomerAddressesListProps = {
  addresses: any
  loading: boolean
  handleDelete?: (id: string) => void
  handleEdit?: (id: string) => void
  handleDefault?: (id: string) => void
}

const CustomerAddressesList: React.FC<CustomerAddressesListProps> = ({ addresses, loading, handleDefault, handleDelete, handleEdit }) => {
  const hasAddresses = !!addresses?.length

  if (hasAddresses) {
    return (
      <>
        {addresses
          ?.filter(address => address?.default)
          ?.map(address => (
            <Box key={address?.id?.toString()} border="1px solid" borderColor="brand.avocado" p={8} w="full" spacing={1} mb={4}>
              <Text variant="caps" mb={4}>
                Primary Address
              </Text>
              <CustomerAddressesItem loading={loading} address={address} primaryAddress={true} />
            </Box>
          ))}
        <Box border="1px solid" borderColor="brand.avocado" p={8} w="full" spacing={4}>
          <Text variant="caps" mb={6}>
            Other Addresses
          </Text>
          <Stack direction="column" width="full" spacing={4}>
            {addresses?.map((address: any) => (
              <CustomerAddressesItem
                key={address?.id?.toString()}
                address={address}
                loading={loading}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                handleDefault={handleDefault}
              />
            ))}
          </Stack>
        </Box>
      </>
    )
  }

  if (loading) {
    return (
      <Box>
        <Spinner />
      </Box>
    )
  }

  // TODO: moves this into language settings
  return (
    <Heading as="p" size="h4" fontWeight={700} mt={6}>
      No addresses
    </Heading>
  )
}

// const MemoCustomerAddressesList = memo(CustomerAddressesList)
export { CustomerAddressesList }
